
import { NgModule } from '@angular/core';
import { SplitPipe } from './split.pipe';
import { CustomTimePipe } from './time.pipe';
import { SafeUrlPipe } from './sanitiseUrl.pipe';
import { AmountInWordsPipe } from './amount-in-words.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { TruncatePipe } from './truncate.pipe'; 
@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    CustomTimePipe,
    SplitPipe,
    SafeUrlPipe,
    AmountInWordsPipe,
    SlugifyPipe,
    TruncatePipe
  ],
  exports: [
    CustomTimePipe,
    SplitPipe,
    SafeUrlPipe,
    AmountInWordsPipe,
    SlugifyPipe,
    TruncatePipe
  ]
})
export class PipesModule {
  transform(value: any) {
    throw new Error('Method not implemented.');
  }
}
